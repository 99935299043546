<template>
  <div class="home-page-wrapper">
    <Header />
  
    <!--  mobile menu-->
    <MobileMenu />

    <b-overlay :show="loading">
      <div v-if="stories.length">
        <section id="services" class="services-section my-0">
          <div class="services-container mt-md-5 mt-n2">
            <div class="grid-col-2">
              <div class="mr-md-4 pr-md-4 mb-3 mb-sm-0">
                <div>
                  <img src="@/assets/images/stories/quote-icon.png" width="120px" class="ml-n2" />
                  <p>
                    {{ getValueFromSource(featuredStory, 'excerpt', '') }}
                  </p>
                  <b-button 
                    style="width: 230px; z-index: 1; position: relative;"
                    variant="primary"
                    block
                    type="submit"
                    size="lg"
                    class="mt-3"
                    @click="goToStoryDetails(featuredStory._id)"
                  >
                    Continue Reading
                    <feather-icon icon="ArrowRightIcon" />
                  </b-button>
                </div>
              </div>

              <div class="d-md-flex align-items-center">
                <b-img :src="getValueFromSource(featuredStory, 'featured_image.path', '')" fluid alt="Featured image" width="600" height="700"></b-img>
              </div>
            </div>

            <div class="position-absolute design" style="top: 70%; left: 0;">
              <div class="d-none pc">
                <svg width="290" height="414" viewBox="0 0 290 414" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g opacity="0.2">
                    <path
                      d="M-59.2238 1.52588e-05H-148.731L205.384 357.05L290 353.137L150.604 213.251L290 69.4535H205.384L104.628 167.275L82.1287 146.244L222.992 1.52588e-05H135.931L38.1089 97.3327L-59.2238 1.52588e-05Z"
                      fill="#ADCD39"
                    />
                    <path
                      d="M-118.406 69.4535H-204L-65.5822 209.339L-204 346.289H-118.406L-21.5624 254.337L0.93663 273.901L-136.992 413.786H-51.398L90.9327 273.901L-118.406 69.4535Z"
                      fill="#459042"
                    />
                  </g>
                </svg>
              </div>
              <div class="d-none mobile">
                <svg width="144" height="217" viewBox="0 0 144 217" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g opacity="0.2">
                    <path
                      d="M-39.1505 7.62939e-06H-86.0439L99.4799 187.061L143.811 185.011L70.78 111.724L143.811 36.3873H99.4799L46.6927 87.637L34.9053 76.6183L108.705 7.62939e-06H63.0926L11.8429 50.9934L-39.1505 7.62939e-06Z"
                      fill="#ADCD39"
                    />
                    <path
                      d="M-70.1565 36.3873H-115L-42.4817 109.674L-115 181.424H-70.1565L-19.4193 133.249L-7.63193 143.499L-79.894 216.786H-35.0505L39.5178 143.499L-70.1565 36.3873Z"
                      fill="#459042"
                    />
                  </g>
                </svg>
              </div>

            </div>
          </div>
        </section>

        <section>
          <div class="d-flex flex-md-row flex-column justify-content-around mt-5 py-4 stat-banner">
            <div class="d-flex flex-column align-items-center mb-2 mb-sm-0">
              <feather-icon size="21" icon="UsersIcon" class="text-success mb-1" />
              <h1 class="font-weight-bolder text-center">
                10k
              </h1>
              <h4>Active users every month</h4>
            </div>

            <div class="d-flex flex-column align-items-center">
              <feather-icon size="21" icon="AwardIcon" class="text-success mb-1" />
              <h1 class="font-weight-bolder text-center">
                #1
              </h1>
              <h4>Companion of all NSS Personnels</h4>
            </div>
          </div>
        </section>

        <section id="other-stories" class="mt-5 other-stories-section">
          <h2 class="font-weight-bolder text-center mb-4">
            Our goal is to support you financially, like we supported others
          </h2>
          <div class="d-flex flex-wrap justify-content-start">
            <StoryCard v-for="(story, index) in stories" :key="index" :story="story" />
          </div>

          <div
            v-if="!viewingAll && stories.length > 2"
            class="text-blue text-center mb-3 view-all cursor-pointer"
            @click="viewMore"
          >
            View all >>
          </div>
          <b-col v-if="viewingAll" cols="12">
            <!-- pagination -->
            <div class="my-5 float-right">
              <b-pagination
                :value="currentPage"
                :total-rows="totalRecords"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                @change="currentPage = $event"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </b-col>
        </section>
      </div>
      <div v-else-if="loading">
        <feather-icon
          icon="LoaderIcon"
          size="60"
          class="mt-5"
        />
        <h2 class="font-weight-bolder text-center mt-3 mb-5 pb-5">
          Loading stories...
        </h2>
      </div>
      <div v-else class="d-flex flex-column align-items-center mt-5 pt-5">
        <feather-icon
          icon="InfoIcon"
          size="60"
          class="mt-5"
        />
        <h2 class="font-weight-bolder text-center mt-3 mb-5 pb-5">
          No stories found.
        </h2>
      </div>
    </b-overlay>

    <Footer />
  </div>
  </template>
  
<script>
import { get } from 'lodash';
import { BLink, BRow, BCol, BButton, BImg, BPagination, BOverlay } from 'bootstrap-vue'

import Header from "@/pages/public/header.vue";
import MobileMenu from "@/pages/public/MobileMenu.vue";
import Footer from "@/pages/public/services/shared/Footer.vue";
import StoryCard from "@/pages/public/stories/components/StoryCard.vue";
import Requirements from "@/pages/public/services/shared/Requirements.vue";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  
const watchHandler = {
  async handler(){
    await this.fetchAllData()
    const element = document.getElementById("other-stories");
    if (element && this.viewingAll) {
      element.scrollIntoView();
    }
  }, 
  immediate: false
}

export default {
    components: {
      MobileMenu,
      Header,
      Footer,
      Requirements,
      StoryCard,
      BImg,
      BRow,
      BCol,
      BLink,
      BButton,
      BOverlay,
      BPagination
    },
    data() {
      return {
        loading: false,
        totalRecords: 0,
        currentPage: 1,
        perPage: 50,
        stories: [],
        viewingAll: false
      }
    },
    computed: {
      featuredStory() {
        return this.stories[0];
      }
    },
    watch: {
      currentPage: watchHandler,
      perPage: watchHandler,
    },
    created() {
      this.perPage = 3;
      this.fetchAllData();
    },
    methods: {
      async viewMore() {
        this.viewingAll = true;
        this.perPage = 6;
      },
      goToStoryDetails(id) {
        this.$router.push({ name: 'single-story', params: { id } })
      },
      async fetchAllData() {
        try {
          this.loading = true;
          const query = {
            limit: this.perPage,
            page: this.currentPage,
          }

          this.$router.push({ query }).catch(() => {});

          const request = await this.useJwt().sharedService.fetchStories(query);
          const { data, pagination } = request.data;
          this.stories = data;

          this.totalRecords = pagination.totalRecords
          this.perPage = pagination.limit;
        } catch (error) {
          const error_message = get(error, "response.data.message") || error.message
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error!',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error_message
            },
          });
        } finally {
          this.loading = false;
        }
      },
    }
}
</script>
  
<style lang="scss" scoped>
  @import '@core/scss/vue/pages/page-public.scss';

  .stat-banner {
    background: #ECF4EC;
  }
</style>
